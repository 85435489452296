@import '../../styles/variables';

.Input {
  position: relative;
  width: 100%;
  input {
    border: none;
    border: 1px solid $border-color-input;
    font-size: 16px;
    line-height: 1;
    padding: 0 0 0 4px;
    font-family: $font-light;
    width: 100%;
    border-radius: 5px;
    letter-spacing: 1.5px;
    height: 35px;
    color: $color-dark-gray;
    margin: 0;
    padding-left: 12px;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $color-gray;
    }
    &::placeholder {
      line-height: 16px;
    }
    &:-webkit-autofill {
      background: red;
    }
    &:disabled {
      background-color: #fafafa;
      cursor: not-allowed;
    }
  }
  .error {
    border-color: $color-red-error;
  }
}

.errorLabel {
  margin: 4px 0 0 0;
  font-size: 0.7em;
  color: $color-red-error;
  border: 1px solid $color-red-error-border;
  padding: 2px 5px;
}

.bottomLabel {
  font-size: 0.75em;
  font-family: $font-regular;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .errorLabel {
    margin-top: 3px;
    width: 100%;
    border-bottom-color: $color-error-red-alert;
  }
}

.label {
  position: absolute;
  top: -14px;
  font-size: 11px;
  color: $color-gray-light2;
}
.labelFlex {
  display: flex;
}

@media (max-width: 600px) {
  .textTooBig {
    position: relative;
    margin-bottom: 0;
    top: 5px;
    width: 100%;
  }
}

.errorLeft {
  float: left;
}

.notColorful {
  border-bottom-color: $color-error-red-alert !important;
  border-color: $color-gray !important;
}

.yellowError {
  color: $color-yellow-action !important;
  border: 1px solid $color-yellow-action !important;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent !important;
  border-bottom: 1px solid $color-gray-light2;
  border-radius: 0;
  cursor: default;
  &.errorGroupText {
    border-bottom: 1px solid $color-error-red-alert;
  }
}

[data-tooltip] {
  &:hover {
    &:before,
    &:after {
      display: block;
      position: absolute;
      right: 0.1%;
      top: 180%;
      font-size: 0.8em;
      color: #000;
      font-size: 0.7rem;
      -webkit-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      white-space: normal;
    }
    &:before {
      border-radius: 0.2em;
      content: attr(datatitle);
      background-color: #fff;
      margin-top: -2.5em;
      padding: 0.4em;
      width: 35%;
      top: 70px !important;
    }
    &:after {
      margin-top: -2.1em;
      margin-left: 1em;
      border-style: solid;
      border-color: transparent;
      border-top-color: black;
      border-width: 0.5em 0.5em 0 0.5em;
    }
  }
}
