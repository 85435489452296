@import '../../styles/_variables.scss';

.btn {
  min-height: 39px;
  min-width: 173px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px 20px;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.btnBlue {
  background-color: $color-blue;
  color: $color-white;
}

.icon{
  width: 25px;
}
