.step2 {
  list-style-type: none;
  width: 100%;
  padding: 0;
  li {
    flex: 1;
  }
  .rowTitle {
    padding-left: 12px;
    .sectionTitle {
      font-size: 17px;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}

.cotizarHeader {
  width: 100%;
  color: #000;
  margin-bottom: 25px;
  padding: 0px 20px;
  h1 {
    font-size: 35px;
    @media (max-width: 800px) {
      font-size: 25px;
    }
    margin: 0;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }
}

.step2Mobile{
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin-bottom: 0px;
  li {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid #e9ecef;
  }
  .benefitsOptional{
    font-size: 17px;
    text-decoration: underline;
    text-transform: uppercase;
    padding: 10px;
  }
 
}

.descriptionMobile{
  width: 40%;
  text-align: right;
}
.tooltipMobile{
  width: 20%;
  text-align: center;
  img{
    text-align: center;
    margin: 0 auto !important;
  }
}
.detailMobile{
  width: 40%;
  text-align: left;
}

.namePlan{
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
}

@media screen and (min-width: 560px) and (max-width: 768px) {
  .descriptionMobile{
    width: 45%;
  }
  .tooltipMobile{
    width: 10%;
  }
  .detailMobile{
    width: 45%;
  }
}

.plansMobile{
  position: relative;
  .btnArrow{
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
  }

  .btnLeft{
    left: 30px;
  }
  .btnRight {
    right: 30px;
  }

  @media screen and (max-width: 400px) {
    .btnLeft{
      left: 5px;
    }
    .btnRight {
      right: 5px;
    }
  }

}


.boxPlans{
  width: 100%;
  margin: 40px auto 20px;
}

.boxPlans_2plans{
  padding: 0 14%;
}

.marginTop{
  margin-top: 25px;
}

.boxPlans :global(.slick-track) {
  display: flex !important;
  justify-content: center;
}

.boxPlans :global(.slick-slide) {
  display: flex !important;
  justify-content: center;
}