@import "../../../styles/variables";

.blogItem {
  margin-bottom: 2%;
  .blogItemImage {
    img {
      height: 130px !important;
      width: 100%;
    }
  }
  .blogItemDetail {
    display: flex;
    flex-direction: column;

    .blogItemDetailTitle {
      h2 {
        margin: 2% 0 3% 0 !important;
        font-family: $font-regular;
        a {
          color: #555 !important;
          font-size: 24px !important;
          line-height: 22px;
          padding: 0 1% !important;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
    .blogItemDetailDate {
      font-size: 15px;
      font-weight: normal;
      text-decoration: italic;
      color: #adadad;
      margin: 0 0 10px 0 !important;
      padding: 0 1% !important;
    }
    .blogItemDetailText {
      min-height: 100px;
      font-size: 13px;
      font-weight: normal;
      color: #555 !important;
      margin-bottom: 20px;
      line-height: 25px;
      text-align: justify;
      padding: 0 1% !important;
      a {
        color: #009ae5;
        font-weight: 200;
        text-decoration: none;
      }
    }
  }
}
