@import '../../../styles/_variables.scss';

.wrapBlogs {
  padding: 6% 0;
  .title {
    color: $color-black;
    font-size: 32px;
    font-family: $font-bold;
    line-height: 40px;
    padding:0 6rem 0;
    text-align: left;
    font-weight: bold;
  }
  .btitle {
    color: $color-black;
    font-size: 20px;
    font-family: $font-bold;
    text-align: left;
    font-weight: bold;
    padding:1rem 0;
  }
  .subtitle {
    padding: 0.2rem 6rem 3rem;
    font-family: $font-semibold;
    font-size: 26px;
    color: $color-black;
    text-align: left;
  
  }

  .blogs {
    max-width: 1300px;
    margin: 0 auto;
    .item {
      border-radius: 7px;
        margin: 0 18px 30px;
        padding: 0px;     
        background-color: $color-white;
        height: 340px;
        box-shadow: 4px 4px 8px #fff;
      @media screen and (max-width: 992px) {
        height: 370px;
        
      }
      .imgArea {
        height: auto;
        img {
          width:100%;
          border-radius: 7px;
        }
      }
      .infoArea {
        display: flex;
        flex-direction: column;
        
        .title {
          font-size: 20px;
          font-family: $font-regular, serif;
          left: 0;
          line-height: 24px;
          color: black;
          margin-left: 0px;
        }
        .content {
          font-size: $font-size-itemContent;
          color: $color-dark-gray;
          font-family: $font-regular;
          text-align: inherit;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .publishDate{
          text-align: center;
          line-height: 13px;
          font-size: medium;
          color: grey;
        }
      }
    }
  } 
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnArrow{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 999;
  cursor: pointer;
}

.btnLeft{
  left: -5px;
}
.btnLeft, .btnRight{  
  height: 35px;
  width: 35px;
  background: $color-white;
  text-align: center;
  border-radius: 5px;
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnRight{
  right: -11px;
}

@media screen and (max-width: 768px) {
  .btnLeft{
    left: 0px;
  }
  .btnRight {
    right: 0px;
  }
}
@media (max-width: 600px) {
  .wrapBlogs{
    .title{
      text-align: left;
      padding: 1rem 1rem 0 !important;
    }
    .subtitle{
      text-align: left;
      padding: 0 1rem 1rem !important;
    }
  }
  
}

.anchorbtn{
  background-color: #0080a4;
    border-radius: 4px;
    bottom: 7px;
    color: #0080a4;
    color: #fff;
    margin: 1rem 0;
    padding-top: 0;
    text-align: center;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    height: 36px;
    line-height: 36px;
    padding-bottom: 0;
    text-transform: capitalize;
    transition: all .3s;
    width: 100px;
}

.anchorbtn:hover{
  outline: none;
  text-decoration: none;
  color:#fff; 
}

.anchorbtn:focus{
  background-color: #0080a4;
  color: #fff;
}
