@import '../../../../styles/variables';

.modal {
  @media screen and (min-width: 1045px) {
    border-radius: 5px;
  }
  z-index: 200;
  margin: -5px 0;
  padding: 2% 4%;
  width: 60%;
  background-color: $color-white;
  @media screen and (min-width: 768px) and (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: $width-medium) {
    width: 100%;
    padding-top: 4%;
  }
  .modalTitle {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 24px;
    .spanClose {
      position: absolute;
      right: -2%;
      top: -10px;
      font-weight: bold;
      z-index: 300;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $width-medium) {
        font-size: 20px;
      }
    }
  }

  .modalBody {
    display: flex;
    padding-top: 2%;
    
    overflow: auto;
    @media(min-width: 1100px) and (max-width: 1500px){
      max-height: 380px;
    }
    @media(max-width: 1280px){
      flex-direction: column;
    }

    .bodyLeft {
      @media(max-width: 1280px){
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    .bodyRight {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding-left: 15px;
      @media(max-width: 1280px){
        padding-left: 0;
      }
    }
  }

  .backgroundBlue {
    background: $color-dark-gray-medium;
    color: #fff !important;
  }

  .item {
    color: $color-black;
    display: flex;
    justify-content: flex-start;
    padding: 20px 15px;
    @media screen and (min-width: 1100px) {
      padding: 4px 8px;
    }

    @media screen and (min-width: 1500px) {
      padding: 20px 8px;
    }
    margin-bottom: 2%;
    flex-direction: row;
    flex-wrap: wrap;
    border: 3px solid $color-gray-light;
    border-radius: 5px;
    cursor: pointer;

    .apresentationArea {
      display: flex;
      align-items: center;
      @media screen and (min-width: 1045px) {
        flex: 3 3;
      }
      @media screen and (max-width: 1045px) {
        width: 70%;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
      }
      .imgArea {
        padding-left: 15px;
        width: 15%;
        img {
          width: 45px;
          height: 45px;
        }
      }
      .infoArea {
        flex: 1 1;
        padding: 0 25px;
        text-align: justify;
        .title {
          font-size: 18px;
          line-height: 20px;
        }
        .detail {
          display: flex;
          font-size: 14px;
          line-height: 15px;
          div {
            margin-right: 4px;
          }
        }
      }
    }
    .selectionArea {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1045px) {
        width: 30%;
        .btnArea {
          width: 100%;
        }
      }

      @media screen and (max-width: 420px) {
        width: 100%;
      }

      @media (min-width: 1045px) {
        flex: 1 1;
      }

      // .btnArea {
      //   display: flex;
      //   align-items: center;
      //   padding-right: 15px;
      //   @media (min-width: 1045px) {
      //     width: 50%;
      //   }
      //   max-width: 128px;
      //   button {
      //     height: 3rem;
      //   }
      // }
      .costArea {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $color-black;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    @media(max-width: $width-medium){
      flex-direction: column;
    }

    h3 {
      margin: 0;
      padding: 0 5px;
      text-align: center;
      @media(max-width: $width-medium){
        margin-bottom: 15px;
      }
    }
    .plan {
      position: relative;
      font-family: $font-light;
      flex: 1 1;
      max-width: 300px;
      font-size: 24px;
      border-right: 1px solid $color-gray-light2;
      text-transform: capitalize;
      @media(max-width: $width-medium){
        max-width: none;
        border: none;
      }
    }
    .cost {
      color: $color-black;
      flex: 1 1;
      strong {
        position: relative;
        .symbol {
          position: absolute;
          font-size: 16px;
          top: 0;
          left: -10px;
        }
      }
    }
    button {
      flex: 1 1;
      height: 45px;
      @media(max-width: $width-medium){
        flex: auto;
        width: 100%;
        max-width: none;
      }
    }
  }
}
