.nosotros {
  width: 100%;
  .wrapBanner {
    width: 100%;
    img {
      width: 100%;
      max-height: 570px;
    }
  }  
}
