@import '../../../styles/variables';

.modalMultitrip {
  position: fixed;
  top: 30%;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 455px;
  background: #fff;
  border: 1px solid $color-blue;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
  z-index: 200;
  @media (max-width: $width-medium) {
    width: 100%;
  }
  .header {
    background: $color-blue;
    height: 30px;
  }
  .content {
    flex: 1 1;
    padding: 0 15px 15px;
    .description {
      flex: 1 1;
      margin: 25px 0;
      font-size: 14px;
      color: $color-dark-gray-medium;
      span {
        color: $color-blue;
        font-weight: bold;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      @media (max-width: $width-medium) {
        flex-direction: column;
        align-items: center;
        button {
          width: 80%;
          max-width: none;
          margin: 2px 0 !important;
        }
      }
      button {
        margin: 0 2px;
        text-transform: none;
        font-size: 14px;
      }
    }
  }
}
