@import '../../../styles/_variables.scss';

.item {
  margin-bottom: 1%;
  .question {
    background-color: transparent;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 700;
    line-height: 25px;
    outline: none;
    padding: 10px 0;
    text-align: left;

    .icon {
      font-size: 1.5em;
      margin-right: 10px;
      color: #009ae5;
    }
  }

  .answer {
    padding: 0 18px;
    padding-left: 37px;
    max-height: 0;
    overflow: hidden;
    color: #555;
    font-size: 13px;
    font-weight: 200;
    transition: max-height 0.35s;
    p {
      line-height: 22px;
    }
  }

  .in {
    max-height: 1000px;
  }
}
