@import '../../styles/variables';

.boxPlans {
  width: 280px;
  // min-height: 440px;
  background-color: $color-white;
  border-radius: 7px;
  border: 2px solid transparent;
  box-shadow: 4px 4px 8px #fff;

  .planBestChoice {
    background: $color-error-red-hover;
    width: 100%;
    padding: 12px 10px;
    color: $color-white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    font-size: 12px;
    font-family: TT Hoves Regular;
  }
.planTagYellowBestchoice{
    font-weight: bold;
    width: 100%;
    padding: 12px 10px;
    color: #000;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
    font-size: 13px;
    margin-bottom: 12px;
    font-family: TT Hoves Regular;
  }
  .planName {
    margin-top: 20px;
    h1 {
      text-transform: capitalize;
      font-family: $font-light;
      text-align: center;
      font-size: 1.8rem;
    }
  }

  .schengen_eligible {
    text-align: center;
    font-size: 14px;
    color: #212529;
  }

  .link_description {
    width: 100%;
    text-align: center;
    font-size: 14px;
    a {
      text-decoration: none;
      color: $color-clean-blue;
    }
  }

  .planTag {
    text-align: center;
    span {
      background: black;
      border-radius: 2px;
      color: #fff;
      font-size: 11px;
      padding: 3px 25px;
    }
  }
  .planTagBestchoice {
    text-align: center;
    span {
      background: red;
      border-radius: 2px;
      color: #fff;
      font-size: 11px;
      padding: 3px 25px;
    }
  }

  .promoOffer{    
      background: red;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      padding: 3px 20px;   
  }

  .planListBenefit {
    list-style: none;
    padding: 0px 10px;
    margin: 20px 0px 5px;
    li {
      display: flex;
      border-bottom: 2px solid $color-gray;
      margin-bottom: 10px;

      .planBenefitIcon {
        margin-right: 10px;
        img {
          width: 35px;
        }
      }
      .planTitleBenefit {
        h2 {
          color: $font-black;
          text-transform: capitalize;
          font-size: 13px;
          letter-spacing: 1px;
          margin: 0px;
        }
      }

      .planSubtitleBenefit {
        h3 {
          color: $font-black;
          font-size: 13px;
          font-family: $font-light;
        }
      }
      .boxTitleBenefit {
        padding-top: 5px;
      }
    }
    li:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  .planValueBenefit {
    .planBoxFinalValue {
      display: flex;
      justify-content: center;
      padding: 0.5em 0;
    }
    .planFinalValue {
      // font-family: $font-black;
      font-size: 30px;
      color: $font-black;
    }

    .currencyValue {
      font-size: 20px;
      padding-right: 5px;
      padding-top: 5px;
    }
    
    .planSymbolValue {
      font-size: 30px;
      // padding-top: 10px;
    }
    .planValue {
      font-size: 18px;
      font-weight: normal;
      text-decoration: line-through;
      color: $font-black;
      margin-left: 100px;
      align-self: center;
    }
  }
  .planMoreBenefits {
    color: $color-dark-gray-medium;
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

.plus {
  display: none;
  margin: 10px 0;
  border: none;
  background: transparent;
  img {
    height: 42px;
    width: auto;
  }
  &:focus {
    outline: none;
  }
}

/** BEST CHOICE **/
.borderBlue {
  border: 4px solid $color-error-red-hover;
}

@media (max-width: $width-extra-large) {
  .plus {
    display: block;
  }
}
