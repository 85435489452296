@import '../../../styles/variables';

.blogDetail {
  width: 100%;
  .wrapBanner {
    width: 100%;
    img {
      width: 100%;
      
    }
  }
  .content {
    display: flex;
    margin-top: 2%;
    .textArea {
      padding: 0 20px 30px;
      text-align: justify;
      h1 {
        color: #555;
        font-family: $font-regular;
        font-size: 37px;
        font-weight: bold;
        margin: 10px 0;
      }
      .text {
        color: black;
        line-height: 25px;
        font-size: 14px;
        font-weight: normal;
        font-family: $font-regular;
      }
      .publishDate{
        text-align: justify;
        line-height: 13px;
        font-size: medium;
        color: grey;
      }
    }
    .formArea {
      min-width: 40%;
      .form {
        position: sticky;
        top: 70px;
      }
    }
  }
}

@media (max-width: $width-large) {
  .blogDetail {
    width: 100%;
    .content {
      flex-direction: column;
      .textArea {
        h1 {
          font-size: 40px;
          margin-bottom: 7%;
        }
      }
      .formArea {
        display: flex;
        min-width: 80%;
        justify-content: center;
        .form {
          position: block;
        }
      }
    }
  }
}
