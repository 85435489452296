@import '../../styles/_variables.scss';

.home {
  width: 100%;
  .buttonQuote {
    text-align: center;
    button {
      font-size: 20px !important;
      margin-bottom: 80px !important;
    }
  }
}

