@import '../../../styles/variables';

.cotizarHeader {
  display: none;
  @media screen and (min-width: 1045px) {
    display: block;
  }
  width: 100%;
  color: #000;
  margin-bottom: 25px;
  h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 600;
    font-family: $font-semibold;
    line-height: 40px;
    margin-left: -1%;
  }
}

.cotizarFillForm {
  background: #f6f8fb;
  padding: 0 4% 2%;
  margin-top: 3%;
  input,
  select {
    &:disabled {
      color: #999 !important;
      cursor: not-allowed;
    }
  }
  .errorLabel {
    margin: 0 13px 10px 16px;
    font-size: 0.7em;
    color: $color-red-error;
    border: 1px solid $color-red-error-border;
    padding: 2px 5px;
    border-color: $color-red-error;
  }
  .detail {
    .formDetail {
      padding-top: 1%;
      margin-bottom: 35px;

      .boxTitle {
        font-weight: bold;
        margin-bottom: 8px;
      }
      .whiteBox {
        background: #fff;
        border-radius: 5px;
        padding: 2% 5%;
        color: $color-black;
        margin-bottom: 15px;
      }

      .title {
        font-family: $font-regular;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .question {
        font-size: 14px;
      }
      .answer {
        font-size: 14px;
      }
      .currency {
        font-size: 0.7em;
        margin-left: 3px;
      }
      .viageDetail {
        .title {
          font-weight: bold;
        }
        .titleViajeiro {
          font-family: $font-black;
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .tripDetail {
          font-family: $font-regular;
          display: flex;
          justify-content: space-between;
        }
      }
      .planeDetail {
        p {
          margin-bottom: 0;
        }
        .plane {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          .title {
            display: inline;
            font-size: 18px;
            line-height: 30px;
            width: 40%;
          }
          .price {
            align-self: center;
            display: inline;
            text-align: right;
            width: 60%;
          }
        }
      }
      .planeOptional {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 16px;
          max-width: 60%;
          line-height: 18px;
        }
        .currency {
          font-size: 11px;
        }
      }

      .totalDetail {
        align-items: center;
        justify-content: space-between;
        display: flex;
        width: 100%;
        p {
          width: 50%;
          font-size: 16px;
          margin-bottom: 5px;
        }
        .red {
          color: $color-red-error;
        }

        .title {
          font-family: $font-regular;
          font-size: 18px;
        }

        .valueFinal {
          font-family: $font-regular;
          font-size: 16px;
          font-weight: bold;
        }
        .valueRight {
          text-align: right;
          .currency {
            font-size: 12px;
          }
        }
        .totalValue {
          font-size: 16px;
          font-family: $font-bold;
        }
      }

      .buttonIcon {
        background-color: unset;
        border: none;
        float: right;
        .icon {
          color: #f07662 !important;
          width: 20px;
          height: 20px;
        }
      }

      .descriptionOPtionalBenefits {
        font-size: 12px;
        color: $color-dark-gray;
        font-weight: bold;
      }

      .cuponArea {
        margin: 25px 0 15px;
        .form {
          display: flex;
          align-items: center;
          .inputArea {
            position: relative;
            flex: 1 1;
            .errorLabel {
              position: absolute;
              left: 0;
              margin: 5px 0 0;
            }
          }
          .buttonArea {
            margin-left: 5px;
            button {
              min-height: min-content;
              height: 40px;
              font-size: 12px;
              padding: 5px 15px;
            }
          }
        }
      }
    }
  }
}

.arrowDots {
  width: 100%;
  border-top: 1px solid $color-gray-light3;
  margin: 5px 0;
}

@media (max-width: $width-small) {
  .cotizarFillForm {
    .detail {
      .formDetail {
        .cuponArea {
          .form{
            flex-direction: column;
            .inputArea {
              width: 100%;
              margin-bottom: 10px;
              .errorLabel {
                position: static;
              }
            }
            .buttonArea {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $width-extra-large) {
  .cotizarFillForm {
    flex-direction: column;
    width: 100%;
    .formContent {
      width: 95%;
      margin: auto;
      border: none;
    }
    .detail {
      margin: auto;
      text-align: justify;
    }
  }
}
