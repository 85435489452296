@import '../../styles/variables';
.boxCotizarFillForm{
  margin-bottom: 60px;
}
.cotizarFillForm {

  display: flex;
  background: #fff;
  padding: 15px;
  margin: 0px 0 0px;
  @media screen and (min-width: 1100px) {
    margin-bottom: 50px;
  }

  input,
  select {
    &:disabled {
      background: $color-gray-light-prod;
      color: #555 !important;
      cursor: not-allowed;
    }
  }
  .footerBtn {
    display: flex;
    margin-top: 24px;
    button {
      border: none;
      height: 40px;
      position: relative;
      text-transform: capitalize;
      font-size: 16px;
      width: 95%;
      background-color: #e8381b;
    }
  }
  .formContent {
    width: 100%;
  }
  .formContentEnglish {
    margin: 4px 10px;
    width: 100%;
    .footerRight {
      justify-content: flex-end;
      padding-right: 15px;
      @media screen and (min-width: 400px) and (max-width: 600px) {
        padding-right: 0px;
        padding-left: 0px;
        margin-left: -10px;
        align-self: flex-start;
      }
      @media screen and (max-width: 400px) {
        max-width: 100%;
        button {
          max-width: 100%;
        }
      }
    }
  }
  .errorArea {
    .errorLabel {
      font-size: 0.8em;
      color: $color-red-error;
      border: 1px solid $color-red-error-border;
      padding: 2px 5px;
      width: 100%;
    }
  }
}
@media (max-width: $width-extra-large) {
  .cotizarFillForm {
    display: block;
    width: 100%;
    .formContentEnglish{
      margin: 0 auto;
    }
    .formContent {
      width: 95%;
    }
    .faqContent {
      border-left: none;
      width: 95%;
      text-align: justify;
    }
  }
}

.buttonBackToTop{
  position: sticky;
  width: 200px;
  top: 80px;
  margin: 0 auto 30px;
  z-index: 400;
  @media screen and (min-width: 320px) and (max-width: 800px){
    top: 70px;
  }
  @media screen and (min-width: 1100px) {
    display: none !important;
  }
  button {
    outline: none;
    height: 35px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}