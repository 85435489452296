@import '../../styles/variables';

.modalAttention {
  width: 100%;
  display: flex;
  justify-content: center;
}

.activate {
  position: absolute;
  z-index: 500;
  display: flex;
  width: 100%;
  left: 0px;
  @media (max-width: $width-medium) {
    width: 100% !important;
  }
}
.width96{
  width: 96%;
}

.backgroundOpacityBlue {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 150;
  background: rgba(45, 89, 114, 0.3);
}
