@import '../../styles/variables';

.contactanos {
  .content {
    width: 1100px;
    margin: 0 auto;
    margin-top: 2%;
    .rowContactanos {
      display: table;
      height: 120px;
      margin-left: 10%;
      width: 900px;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      &.right {
        margin-top: -76px;
        margin-left: 241px;
      }
      &.thirdLine {
        margin-top: -69px;
      }
      .text {
        display: table-cell;
        float: left;
        width: 400px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .image {
        float: left;
        display: table-cell;
        width: 385px;
        img {
          height: auto;
          width: 385px;
          &.left {
          }
        }
      }
      h5 {
        color: #ff1721;
        font-family: $font-regular;
        font-size: 28px;
        font-weight: bold;
        line-height: 48px;
        margin-top: 50px;
        margin-bottom: 0;
        width: 100%;
      }
      p {
        color: #555;
        font-weight: 200;
        font-size: 13px;
      }
    }
  }
  .buttonQuote {
    text-align: center;
    button {
      margin-top: 40px;
      font-size: 16px !important;
      margin-bottom: 80px !important;
    }
  }
  @media (max-width: $width-extra-large) {
    .content {
      display: block;
      height: auto;
      width: 100%;
      .rowContactanos {
        display: block;
        float: left;
        position: relative;
        margin: 0 2%;
        width: 96%;
        .text {
          padding-left: 0;
        }
        .image {
          display: none;
        }
        &.right {
          float: left;
          margin-top: 0;
          margin-left: 2%;
        }
        &.thirdLine {
          margin-top: 0;
          margin-left: 2%;
        }
        h5 {
          margin-top: 0;
        }
      }
    }
  }
}
