@import '../../styles/_variables.scss';

.faq {
  width: 100%;
  .wrapBanner {
    width: 100%;
    img {
      width: 100%;
      max-height: 570px;
    }
    .bannerInfo {
      display: flex;
      position: absolute;
      color: $color-white;
      padding-left: 38px;
      top: 100px;
      width: 80%;
      .textArea {
        flex: 1;
        margin-top: 170px;
        .title {
          font-size: 52px;
          margin: 10px 0px;
          line-height: 40px;
        }
        .content {
          font-size: 16px;
          font-family: Sans Pro Regular;
          margin-top: 20px;
        }
      }
    }
  }
  .wrapQuestions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2%;
    padding: 0 15px;
    .title {
      width: 100%;
      margin-bottom: 1%;
      h1 {
        color: #ff1721;
        font-family: $font-regular;
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        margin: 0;
      }
    }
    h3 {
      font-size: 45px;
      font-weight: bold;
      font-family: $font-regular;
      margin: 2% 0;
      text-align: center;
    }
    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
    }
  }
}

.widthFull {
  width: 100%;
}
