@import '../../styles/variables';

.wrapBanner {
  width: 96%;
  margin: 0 2%;
  position: relative;
  
  img {
    border-radius: 40px;
    width: 100%;
  }
  .bannerInfo {
    position: absolute;
    color: $color-white;
    bottom: 5%;
    width: 100%;
    .textArea {
      margin-top: 170px;
      max-width: 50%;
      .title {
        font-size: 52px;
        margin: 10px 0px;
        line-height: 1;
      }
      .content {
        font-size: 16px;
        font-family: Roboto;
        margin-top: 20px;
        line-height: 1;
      }
    }
    .formArea {
      max-width: 100%;
      
    }
  }
}

.note{
  color: red;
  font-size: 20px;
  font-family: 'Roboto';
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1180px) {
  .wrapBanner {
    .bannerInfo {
      .bannerInfoForm{
        width: 80%;
        margin: 0 auto;
      }
    }
  }
   
  
  .formMobile {
    .formArea{
      display: none;
      width: 100%;

    }
  }
  
}

@media (min-width: 1180px) {
  .wrapBanner{
    .bannerInfo{
      padding-left: 38px !important;
      max-width: 50%;
      bottom: 3%;
    }
  }
  .formArea{
    padding-left: 21px;
  }
  .formMobile{
    display: none;
  }
  
}
@media (min-width: 1243px){
  .wrapBanner{
    .bannerInfo{
      padding-left: 38px !important;
      max-width: 50%;
    }
  }
}

@media (max-width: $width-medium) {
  .wrapBanner {
    padding: 10px auto;
    .bannerInfo {
      position: inherit;
      top: 0;
      .textArea {
        color: #555;
        margin: 5% 0;
        text-align: center;
        max-width: 100%;
        .title {
          font-size: 30px;
          font-weight: bold;
          line-height: 30px;
          margin: 0 0 2% 0;
        }
        .content {
          font-size: 13px;
          margin: 0;
          line-height: 17px;
        }
      }
    }
  }
  
  .formMobile {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 1180px) {
  .wrapBanner {
    .bannerInfo {
      position: inherit;
      .textArea {
        color: #555;
        margin: 5% 0;
        text-align: center;
        max-width: 100%;
        .title {
          font-size: 30px;
          font-weight: bold;
          line-height: 30px;
          margin: 0 0 2% 0;
        }
        .content {
          font-size: 13px;
          margin: 0;
          line-height: 17px;
        }
      }

    }
  }
}
