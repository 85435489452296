@import '../../../styles/_variables.scss';

.asistentContent {
  width: 100%;
  padding: 3rem 2rem;
  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: $width-large) {
      overflow: auto;
      // padding-left: 30px;
    }
    .item {
      .imgArea {
        text-align: center;
        margin-bottom: 3%;
        .rounded {
          width: 130px;
          margin: 0 auto;
          height: 130px;
          border-radius: 50%;
          img {
            width: 120px !important;
            height: auto;
            padding: 20px;
            text-align: center;
            vertical-align: middle;
          }
          &.blue {
            background-color: #00aec6;
          }
          &.pink {
            background-color: #e196aa;
          }
          &.yellow {
            background-color: #fcd385;
          }
          &.green {
            background-color: #027180;
          }
        }
        img {
          text-align: center;
        }
      }
      .infoArea {
        display: flex;
        flex-direction: column;
        .title {
          color: #00008f;
          font-family: $font-bold;
          font-size: 26px;
          font-weight: bold;
          line-height: 36px;
          margin: 0;
          padding-bottom: 0.5em;
          padding-top: 0.5em;
          text-align: center;
        }
        .content {
          font-size: 14px;
          font-family: $font-regular;
          color: #333;
          font-weight: 200;
          text-align: justify;
          padding: 5px;
          line-height: 22px;
          a {
            text-decoration: none;
            color:$color-light-blue;
          }
        }
      }
      @media (max-width: $width-large) {
        .imgArea {
          .rounded {
            // text-align: center;
            // background-color: gold;
            // img {
            //   text-align: center;
            //   width: 100%;
            // }
          }
        }
        .infoArea {
          .title {
            font-size: 30px;
            margin: 0;
          }
          .content {
          }
        }
      }
    }
  }
}
