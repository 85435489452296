.benefitRow {
  display: flex;
  list-style-type: none;
  padding: 0;
  .itemDetail {
    justify-content: center;
  }
  .itemDescription {
    justify-content: space-between;
    span {
      margin-right: 4px;
    }
  }
  li {
    display: flex;
    flex: 1 1;
    font-size: 13px;
    padding: 12px;
    width: 240px;
  }
}
