@import '../../../styles/_variables.scss';

.wrapBenefits {
  margin: 0;
  width: 100%;
  background-color: #f0f3f5;
  padding-bottom: 6%;
  .title {
    color:$dark-grey;
    font-family: $font-bold;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    word-break: break-word;
    padding: 6% 7.5%;
  }
  .benefits {
    max-width: 1300px;
    margin: 0 auto;
    .list {
      padding: 0 6.5%;     
      .item {
        border-radius: 7px;
        margin: 0 18px 30px;
        padding: 0px;     
        background-color: $color-white;
        height: 340px;
        box-shadow: 4px 4px 8px #fff;
        @media screen and (max-width: 992px) {
          height: 370px;

        }
        .imgArea {
          text-align: center;
          img {
            max-width: 160px;
            height: 160px;
            margin: 0 auto;
            padding: 1rem;
          }
        }
        .infoArea {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 21px;
            margin-top: 0px;
            margin-bottom: 0px;
            color: $dark-grey;
            font-family: $font-regular;
          }
          .content {
            color: $color-dark-gray;
            font-size: $font-size-itemContent;
            font-family: $font-regular;
            padding: 1% 7.5% 2%;
          }
        }
      }
    }
  }
}

.wrapButton {
  display: flex;
  justify-content: center;
  .aMoreBenefits{
  color: #fff;
  background-color: $color-blue-dark;
  border: 2px solid $color-blue-dark;
  font-size: 20px !important;
  border-radius: 7px;
  text-transform: none;
  margin-top: 50px;
  padding-top: 0px;
  height:60px;
  width: 300px;
  max-width: 180px;
  padding-top: 12px;
  padding-bottom: 0;
  text-align: center;
  font-weight: 600;

  &:focus {
    outline: none;
  }
}
.aMoreBenefits:focus{
  outline: none;
}
.aMoreBenefits:hover{
  text-decoration: none;
}
  }

.btnMoreBenefits{
  font-size: 20px !important;
  border-radius: 7px;
  text-transform: none;
  text-align: center;
  margin-top: 50px;
  padding-top: 0px;
  height: 60px;
  max-width: 180px;
  padding-top: 1px;
  padding-bottom: 0;
 
  &:focus {
    outline: none;
  }
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnArrow{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 999;
  cursor: pointer;
}

.btnLeft{
  left: -60px;
}
.btnLeft, .btnRight{  
  height: 35px;
  width: 35px;
  background: $color-white;
  text-align: center;
  border-radius: 5px;
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnRight{
  right: -60px;
}

@media screen and (max-width: 768px) {
  .btnLeft{
    left: -30px;
  }
  .btnRight {
    right: -30px;
  }
  .imgArea {
    img {
      max-width: 200px !important;
      height: 200px !important;
      margin: 0 auto;
      padding: 0 1rem !important;
    }
  }
  
}

@media (max-width: 950px) {
  .benefits{
    .item{
      height: auto !important;
      
    }
  }
  
}