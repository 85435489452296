@import '../../../../../styles/_variables.scss';

.marginBottom2 {
  .labelTitleForm {
    font-size: 16px;
    font-family: $font-regular;
    text-transform: capitalize;
    font-weight: bold;
  }
}

.PaymentArea {
  background-color: $dark-grey;
  border-radius: 5px;
  margin-right: 0;
  padding: 5% 4%;
  color: #fff;
  .labelTitle {
    font-size: 14px;
    font-family: $font-regular;
    margin: 0.5em 0px 0px 0px;
  }
  .customLabelTitle {
    font-size: 13px;
  }
  .textPaypal {
    color: #fff;
    font-size: 12px;
    margin-top: 2%;
  }

  .tarjetas {
    margin: 0 0 10px 15px;
    background: #fff;
    border-radius: 10px;
    min-height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 70%;
    }
  }

  .paymentCheck {
    border: 3px solid $color-red-button;
  }

  .inputField {
    div {
      margin-top: 0px;
    }
  }

  .cvc {
    input {
      text-align: left !important;
    }
  }

  .checkArea {
    display: flex;
    margin-top: 15px;
    p {
      font-size: 14px;
      font-family: $font-regular;
    }
  }
  .infoFactura {
    font-size: 14px;
  }
}

.flexBaseline {
  display: flex;
  align-items: baseline;
}
.numberPagos {
  select {
    max-width: none;
  }
}

.termsArea {
  margin: 2% 0 8% 15px;
  .checkArea {
    display: flex;
    width: 100%;
    margin-top: 15px;

    p {
      margin-bottom: 0;
      &.description {
        font-size: 14px;
        line-height: 1;
        margin-top: 3px;
        a {
          text-decoration: underline;
          color: #fff;
        }

        span {
          font-weight: bold;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
  .checkBoxError {
    border: 1px solid $color-yellow-action;
    padding: 2px 5px;
    font-size: 11px;
    color: $color-yellow-action !important;
    margin-top: 10px;
  }
}

.checkAreaFactura {
  display: flex;
  margin: 15px 0 0 15px;
}

.totalDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  p {
    margin: 0 5px;
    font-size: 12px;
  }
  .red {
    color: $color-red-error;
  }

  .total {
    .title {
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .valueFinal {
    font-family: $font-regular;
    font-size: 28px;
    font-weight: bold;
  }
  .valueRight {
    text-align: right;
  }
}

.footerBtn {
  text-align: center;
  width: 100%;
  .btnGreater {
    button {
      height: 60px;
      padding: 0 40px;
      .icon {
        margin-left: 2px;
      }
    }
  }
  button {
    max-width: none !important;
    font-size: 16px;
    height: 47px;
    width: 100%;
    text-transform: capitalize;
  }
}

.description {
  font-size: 14px;
  line-height: 1;
  margin-top: 3px;
}
