@import '../../../styles/_variables.scss';

.wrapAdvertisements {
  margin-bottom: 6%;
  .title {
    margin-bottom: 6%;
    margin-top: 1%;
    h4 {
      color: $color-black;
      font-size: 30px;
      font-family: $font-bold, serif;
      font-weight: bold;
      margin-bottom: 0;
      text-align: left;
      padding-left: 8%;
    }
  }
  .advertisements {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0 70px;
    .item {
      min-width: 168px;
      padding: 15px 30px;
      .imgArea {
        text-align: center;
        margin-bottom: 30px;
        img {
          width: 140px;
          height: 140px;
          @media (max-width: $width-medium) {
            width: 120px;
            height: 120px;
            
          }
        }
      }
      .infoArea {
        display: flex;
        flex-direction: column;
        .title {
          font-family: $font-bold, serif;
          font-size: $font-size-itemTitle;
          font-weight: bold;
          line-height: 27px;
          min-height: 20px;
          padding-bottom: 0px;
          text-align: center;
          margin-top: 0px;
        }
        .content {
          font-size: $font-size-itemContent;
          color: $color-dark-gray;
          text-align: left;
          font-family: $font-regular;
        }
      }
    }
  }
  @media (max-width: $width-medium) {
    .advertisements {
      padding: 0;
      .item {
        padding: 25px 20px;
      }
    }
  }
}
