@import '../../../../../../../styles/variables';

.modalChangeAge {
  position: fixed;
  @media screen and (min-width: 1045px) {
    border-radius: 5px;
  }
  top: 30%;
  z-index: 200;
  margin: -5px 0;
  padding: 2% 2%;
  width: 30%;
  background-color: $color-white;
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: $width-medium) {
    width: 80%;
    padding-top: 4%;
    padding-left: 2px;
    padding-right: 2px;
  }

  .modalTitle {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 24px;
    .spanClose {
      position: absolute;
      right: 10px;
      top: -10px;
      font-weight: bold;
      z-index: 300;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $width-medium) {
        font-size: 20px;
      }
    }
  }

  .header {
    position: relative;
    background: $color-blue;
    height: 30px;
    .spanClose{
      color: white;
      position: absolute;
      right: 2%;
      top: 10%;
    }
  }

  .message{
    padding: 20px 10px;
    .title{
      color: $color-dark-gray-medium;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 8px;
      
      font-family: $font-regular;
    }
    .contentMessage{
      font-size: 16px;
      .item{
        padding-left: 10px;
      }
    }
  }

  .footer{
    margin: 0 10px 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}
