@import '../../styles/variables';

.btn {
  // Visa Button
  background: $kayak-blue;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 4px;
  border: none;
  height: 36px;
  width: 100%;
  max-width: 221px;
  text-align: center;
  color: $color-white;
  font-size: 14px;
  line-height: 36px;
  text-transform: capitalize;
  padding-top: 1px;
  padding-bottom: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }

}

.btnDanger {
  background: $color-error-red;
  &:hover {
    background: $color-error-red-hover;
  }
}

.btnSuccess {
  background: $color-success-green;
  &:hover {
    background: $color-success-green-hover;
  }
}

.btnItemList {
  font-size: 12px;
  text-transform: none;
  font-family: $font-regular;
}
.btnWhite {
  background: $kayak-blue;
  color: $color-orange;
  border: 2px solid $kayak-blue;
  line-height: unset;
}
.btnWhiteBlue {
  background: $kayak-blue;
  color: $color-white;
  border: 2px solid $kayak-blue;
  line-height: unset;
}
.whiteRed {
  background: $kayak-blue;
  color: $color-red-banner;
  border: 2px solid $kayak-blue;
  line-height: unset;
}
.btnYellow {
  line-height: unset;
  background: $kayak-blue;
  color: $color-white;
  border: $kayak-blue;
  &:focus,
  &:hover {
    background: $kayak-blue;
    border: $kayak-blue;
  }
}
.btnBlue {
  background: $kayak-blue;
  color: $color-white;
  border: 2px solid $kayak-blue;
  line-height: unset;
  min-height: 45px;
}

.btnRed{
  background: $color-red-button;
  color: $color-white;
  border: 2px solid $color-red-button;
  line-height: unset;
  min-height: 45px;
} 

.btnOrange {
  background-color: $color-orange;
}

.btnTransparent {
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.btnMoreBenefits {
  color: $kayak-blue;
  background-color: transparent;
  border: 2px solid $kayak-blue;
  font-size: 20px;
  border-radius: 4px;
  text-transform: none;
  text-align: center;
  font-weight: 100;
  margin-top: 50px;
  padding-top: 0px;

  &:focus {
    outline: none;
  }
}

.btnBlogsList {
  color: $kayak-blue;
  background-color: $kayak-blue;
  border-radius: 4px;
  text-transform: none;
  text-align: center;
  margin: 1rem 0;
  width: 100px;
  padding-top: 0px;
  bottom: 7px;
  color:$color-white;
  &:focus {
    outline: none;
  }
}

.btnIconButton {
  position: absolute;
  max-width: 30px;
  font-size: 14px;
  top: 35%;
  right: 5%;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  @media(max-width: 920px){
    font-size: 12px;
    top: -10px;
    right: 5%;
  }
  @media(max-width: 750px){
    font-size: 10px;
    top: -15px;
    right: 5%;
  }
}

.btnHomeBanner {
  background-color: $kayak-blue;
  border: 1px solid #cfcfcd;
  border-radius: 4px;
  max-width: 100%;
  height: 55px;
}

.btnStep1Banner {
  background-color: $color-red-banner;
  border: 1px solid #cfcfcd;
  border-radius: 4px;
  max-width: 100%;
  height: 55px;
}
.btnHoursBanner {
  background-color: $color-hours-button;
  border: 1px solid #cfcfcd;
  border-radius: 8px;
  height: 55px;
}


