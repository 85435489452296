@import '../../styles/_variables.scss';

.blog {
  width: 100%;
  .wrapBanner {
    width: 100%;
    img {
      width: 100%;
      max-height: 570px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 3%;
    padding: 0 15px;
  }
}
