@import '../../../styles/variables';
.planBtnSelect{
  display: flex;
  justify-content: center;
  width: 100%;
  .btnSelect {
    text-transform: capitalize;
    border-radius: 5px;
    font-family: $font-regular !important;
    letter-spacing: 1px;
    font-size: 15px !important;
    color:  $color-white;
  }
  .btnNotRecommended {
    color:  $color-white;
    background: $kayak-blue;
    border: 1px solid  $color-blue;
    min-height: 45px;
  }
}