@import '../../styles/_variables.scss';

.cotizar {
  width: 100%;
  background: #f5f5f5;
  .wrapBanner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f3f5;
    .wrapBtnBack {
      @media screen and (max-width: 1045px) {
        display: none;
        svg {
          color: #009ae5;
        }
        button {
          color: #009ae5;
        }
      }
      position: absolute;
      left: 0;
      padding: 8px;
      margin-left: 3%;
      button {
        font-size: 14px;
        display: flex;
        align-items: center;
        span{
          margin-left: 8px;
        }
      }
    }
    .cotizarAsistencia {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5%;
      width: 100%;
      .cotizarHeader {
        display: none;
        @media screen and (min-width: 1045px) {
          display: block;
        }
        width: 100%;
        color: #000;
        margin-bottom: 25px;
        padding-left: 38px;
        h1 {
          font-size: 35px;
          margin: 0;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
        }
      }
      .form {
        padding: 0;
        @media screen and (min-width: 768px) and (max-width: 900px) {
          width: 80%;
        }
      }
      .formAll{
        padding: 14px;
        width: 97%;
      }
      .greater {
        width: 100%;
        @media screen and (max-width: 1045px) {
          width: 100%;
        }
      }
      
    }
  }
}
@media (max-width: $width-medium) {
  .cotizar {
    .wrapBanner {
      .wrapBtnBack {
        margin: 0;
        button {
          font-size: 12px;
        }
      }
      .cotizarAsistencia {
        width: 100%;
        .cotizarHeader {
          h1 {
            font-size: 35px;
          }
        }
        .form {
          width: 100%;
        }
        .formAll{
          width: 100%;
        }
      }
    }
  }
}
