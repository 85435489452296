@import '../../../styles/variables';

.modal {
  @media screen and (min-width: 900px) {
    border-radius: 5px;
    
  }

  @media (orientation: landscape) {
   min-height: 500px;
  }

  height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -30px;
  
  z-index: 200;
  margin: 15px 0;
  width: 50%;
  background-color: $color-white;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: $width-medium) {
    width: 100%;
    padding-top: 4%;
    top: -30px;
    height: calc(100vh - 100px);
  }

  @media (orientation: landscape) {
    body {
      flex-direction: row;
    }
  }
  .modalTitle {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 24px;
    padding: 4% 4%;
    color: #3a4a5c !important;
    strong {
      font-family: $font-bold;
      font-size: 32px;
    }

    .spanClose {
      position: absolute;
      right: 0px;
      top: 0px;
      font-weight: bold;
      z-index: 300;
      width: 50px;
      height: 50px;
      background: #abb1bb;
      color: #fff;
      font-weight: normal;
      text-align: center;
      padding: 8px;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $width-medium) {
        font-size: 20px;
      }
    }
  }

  .modalBody {
    display: flex;
    padding: 2% 4%;
    background: #fafafa;
    overflow: auto;
    @media (max-width: $width-extra-large) {
      flex-direction: column;
    }

    .bodyLeft {
      @media (max-width: $width-extra-large) {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
    }

    .bodyRight {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding-left: 15px;
      @media (max-width: $width-extra-large) {
        padding-left: 0;
      }
    }
  }

  .borderTopFooter{
    border-top: 10px solid #e6e6e6;
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 10px solid #e6e6e6;
    @media (max-width: $width-medium) {
      flex-direction: column;
    }
  }
    
}

.listBenefits {
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  li {
    ul {
      li {
        padding: 12px 0px 0px;
        color: $color-blue;
        justify-content: flex-start !important;

        div {
          margin-left: 20px;
        }
      }
      li:last-child {
        justify-content: flex-end !important;
        color: #95a3b1 !important;
        text-align: right;
      }
    }
  }
}

.sectionTitle {
  padding: 5px 0px;
  font-size: 18px;
}

.listBenefitIncluded{
  display: flex;
  padding: 30px 0px;
  border-bottom:1px solid #e9ecef;
  .benefitsIncluded {
    color: $color-blue;
    justify-content: flex-start !important;
    font-weight: bold;
    flex: 1 1;
  }

  .boxSchengenEligible{
    .schengenEligible{
      border-radius: 50px;
      background: $color-white;
      padding: 10px 15px;
      color: #95a3b1 !important;
      border: 1px solid #95a3b1 !important;
      font-size: 13px;
    }
  }

}

.bestChoice{
  width: 100%;
  padding: 10px;
  color: $color-white;
  background-color: $color-blue;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}

.boxPlanSelected{
  display: flex;
  padding: 20px 4%;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: $width-medium) {
    flex-direction: column;
  }
  .productName{
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-light;
  }

  .planValueBenefit{
    .planBoxFinalValue{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .planFinalValue{
      font-family: $font-black;
      font-size: 30px;
      color: #555555;
    }
    .planSymbolValue{
      font-size: 15px;
      padding-top: 10px;
    }
    .planValue{
      font-size: 15px;
      font-weight: normal;
      text-decoration: line-through;
      color: $font-black;
      margin-left: 10px;
      align-self: center;
    }
  }
  .btnSelecPlan{
    display: flex;
    align-items: center;
    width: 150px;
  }
}

