@import '../../../styles/_variables.scss';

.content {
  line-height: 25px;
  width: 100%;
  ul {
    li {
      font-size: 13px;
    }
  }
}
