@import '../../styles/variables';

.container {
  @media (max-width: 600px) {
    max-width: 100% !important;
  }

  img {
    display: initial !important;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .btnArrow {
    position: absolute;
    z-index: 500;
    background-repeat: no-repeat;
    top: 20%;
    height: 30px;
    color: transparent;
    &:focus,
    &:hover {
      opacity: 0.6;
      outline-color: transparent;
      cursor: pointer;
    }
  }

  .btnPrev {
    //background-image: url("../../../../assets/img/icon-arrow-left.svg");
    left: 0;
    @media (max-width: 600px) {
      left: -20px;
    }
  }

  .btnNext {
    //background-image: url("../../../../assets/img/icon-arrow-right.svg");
    right: 0;
    @media (max-width: 600px) {
      right: -20px;
    }
  }

  .dot {
    &:before {
      margin-top: 12px;
      font-size: 15px !important;
      color: #fff !important;
    }
  }
  @media (max-width: $width-medium) {
    &.caroulselContainer {
      display: none;
    }
  }
}

.slickCarousel {
  position: relative;
  .slickDisabled {
    opacity: 0.6;
  }
  .dotsClass {
    list-style: none;
    margin: 0 0 0rem;
    position: absolute;
    text-align: center;
    width: 100%;
    padding-left: 0;
    bottom: -5px;
    li {
      display: inline-block;
      margin: 0 0.5rem;
      min-height: 12px;
    }
    li[class='slick-active'] {
      button {
        background-color: #fff;
      }
    }

    button {
      background-color: #bcb1a5;
      border: 0;
      border-radius: 100px;
      color: transparent;
      cursor: pointer;
      font-size: 0;
      display: block;
      height: 12px;
      width: 12px;
      line-height: 0;
      outline: none;
      transition: background-color 200ms;
      margin: 0px;
      &:focus,
      &:hover {
        background-color: #fff;
      }
    }
  }
}
