.main-section {
  width: 80%;
  margin: 0 auto;
  font-size: 1.2rem;
  font-family: 'Source Sans Pro Regular';
  margin-bottom: 50px;
}

.sub-section p {
  font-weight: 400;
}

.sub-section h1 {
  font-size: 1.75rem;
  font-weight: 600;
}

.sub-section h2 {
  font-family: 'Source Sans Pro Regular';
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 35px;
  margin-top: 35px;
}

.sub-section h4 {
  font-size: 1.12rem;
  font-weight: 700;
}

.sub-section ul li::marker {
  color: #4976ba;
}

.sub-section table {
  border-spacing: 0;
  margin-bottom: 20px;
}

.sub-section table thead {
  font-weight: 600;
}

.sub-section table th,
.sub-section table td {
  padding: 5px 10px;
  border: 1px solid black;
}

.li__cookie-function {
  text-decoration: none;
}

.p__cookie-settings-page {
  font-weight: 600;
}

.li__link {
  color: #00008f;
}
.cookie-heading{
  text-transform: uppercase;
}
