@import "../../styles/variables";

.react-datepicker__header {
  background: $color-blue;
  * {
    color: #fff;
  }
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999;
}
