@import '../../styles/variables';

.stepContainer {
  display: flex;
  align-items: center;
  width: 50%;
  min-width: 430px;
  
  @media (max-width: 800px){
    margin: 0 auto;
  }
  @media (max-width: 542px) {
    min-width: 320px;
  }

  @media (max-width: 400px) {
    min-width: 280px;
    
  }

  .step {
    font-size: 12px;
    font-weight: 600;
    &:nth-of-type(2){
      cursor: pointer;
    }
    
    @media (max-width: 542px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .dot {
      font-size: 14px;
      border: 2px solid;
      padding: 3px 8px;
      border-radius: 50%;
      margin-right: 5px;
      max-height: 25px;
      max-width: 25px;
    }
    .clickable{
      
      text-decoration: underline;
      color: #0080a4;
      text-decoration-thickness: 2px;
      &:hover{
        color: $color-light-blue;
      }
    }
  }
  .stepSelected {
    .dot {
      border-color: $color-light-blue;
      color: $color-light-blue;
    }
    .bar{
      margin: 0 10px;
    }
  }
  .stepDone {
    
    .dot {
      background-image: url('../../assets/img/CheckCircle_icon.svg');
      background-position: center;
      background-size: cover;
      border-color: $color-light-blue;
      color: transparent;
      // background-color:$color-light-blue;
    }
  }
  .stepBlocked {
    opacity: 0.3;
    .dot {
      background-color: $color-gray-light3;
    }
    .bar{
      margin: 0 10px;
    }
  }

  .bar {
    flex: 1 1;
    margin: 0 10px;
    border-top: 3px solid;
  }
  .barDone {
    border-color: $color-light-blue;
    margin: 0 10px;
  }
  .barBlocked {
    opacity: 0.3;
    margin: 0 10px;
  }
}

@media (min-width: 943px){
  .stepContainer{
    padding-right: 3rem;
  }
}
