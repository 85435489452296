@import '../../styles/_variables.scss';



.staticPageContent {
  .content {
    padding: 0 3%;
    h1 {
      color: #555;
      font-size: 30px;
      font-weight: bold;
      font-family: $font-regular;
      text-align: center;
      padding: 1rem 0 0;
    }
    .item {
      .description {
        color: #555;
        font-size: 0.9rem;
        text-align: justify;
        &.listNumber {
          ul {
            list-style-type: decimal;
          }
        }
      }
      h4 {
        color: #555;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1%;
      }
      p,
      span,
      h4 {
        font-family: $font-regular !important;
      }
    }
  }
  @media (min-width: $width-small) {
  }
  @media (min-width: $width-medium) {
  }
  @media (min-width: $width-large) {
  }
  @media (min-width: $width-extra-large) {
  }
}
