@import '../../styles/variables';

.cotizarStep4 {
  display: flex;
  justify-content: center;

  .content {
    margin: 4px 10px;
    padding: 4rem 1rem 0;
    text-align: center;
  }
}
@media (max-width: $width-extra-large) {
  .cotizarStep4 {
    flex-direction: column;
    width: 100%;
    .content {
      width: 95%;
      margin: auto;
      border: none;
    }
  }
}
