@import '../../../styles/variables';

.modal {
  @media screen and (min-width: 700px) {
    border-radius: 30px;
    
  }

  @media (orientation: landscape) {
   min-height: 300px
  }
  
  //height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //position: absolute;
    top: -50px;
  
  z-index: 200;
  margin: 15px 0;
  width: 30%;
  height: 10%;
  background-color: $color-white;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: $width-medium) {
    width: 100%;
    padding-top: 4%;
    top: -30px;
    height: calc(100vh - 100px);
  }

  @media (orientation: landscape) {
    body {
      flex-direction: row;
    }
  }
  .modalTitle {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 20% 4%;
    color: #3a4a5c !important;
    strong {
      font-family: $font-publicBanner;
      font-size: 22px;
    }

    .spanClose {
      position: absolute;
      right: 0px;
      top: 0px;
      font-weight: bold;
      z-index: 300;
      width: 50px;
      height: 50px;
      background: #abb1bb;
      color: #fff;
      font-weight: normal;
      text-align: center;
      padding: 8px;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $width-medium) {
        font-size: 20px;
      }
    }
  }

  .modalBody {
    display: flex;
    padding: 2% 4%;
    overflow: auto;
    color:#abb1bb;
    border-radius: 10%;
    @media (max-width: $width-extra-large) {
      flex-direction: column;
    }

    .bodyLeft {
      @media (max-width: $width-extra-large) {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
    }

    .bodyRight {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding-left: 15px;
      @media (max-width: $width-extra-large) {
        padding-left: 0;
      }
    }
  }

   
}