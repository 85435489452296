@import '../../../../../styles/variables';

.cotPeriodlArea {
  margin-top: 0.7rem;
  select {
    @media screen and (max-width: 1045px) {
      max-width: 100% !important;
    }
  }
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
  }
  select {
    background-color: $color-white !important;
    border: 2px solid $color-gray;
    border-radius: 5px;
  }
}

.labelTitle {
  font-size: 16px;
  color: $color-gray-light5;
  margin-bottom: 0px;
}
.labelAgeTitle{
  font-size: 16px;
  color: $color-gray-light5;
  margin: 10px 0 0;
}

.agesArea {
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  .labelErrorGroup {
    font-size: 0.7em;
    color: $color-red-error;
    border: 1px solid $color-red-error-border;
    padding: 2px 5px;
  }
  
}

.personsBtn{
  background-color: $kayak-blue;
  height: 35px;
  width: 26%;
  text-align: center;
}

.fontAWPlus, .fontAWMinus{
  font-size: 14px;
  color: $color-white;
  
}
.btnAWMinus{
  margin-right: 3%;
}
.btnAWPlus{
  margin-left: 3%;
}

.numberTraleversInputs 
{
  input{
      font-size: 16px;
      letter-spacing: 1px;
      padding: 0;
      margin: 0;
      text-align: center;
      border: 1px solid #cad1d6;
      border-radius: 5px;
  }
}
.emailField{
  input{
    border: 2px solid #d9e2e9;
    height: 35px;
    margin-bottom: 2%;
  }
}
