@import '../../../../../styles/variables';

.rentalPeriodlArea {
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
  }
  input {
    background-color: $color-white !important;
    border: 1px solid $color-gray;
    border-radius: 5px;
  }
  select {
    // max-width: 220px;
    background-color: $color-gray-light !important;
    border: 1px solid $color-gray !important;
    border-radius: 5px;
    // @media screen and (max-width: 1045px) {
    //   max-width: 100% !important;
    // }
  }
}

.labelTitle {
  font-size: 16px;
  color: $dark-grey;
  margin-bottom: 0px;
}
