@import '../../styles/variables';

@media (max-width:440px){
  .cotizarFillForm {
      padding:0 !important
    }
  
}
@media (max-width: 768px){
  .cotizarFillForm{
    margin-bottom: 0.5rem !important;
  }
}
.noMaxWidth{
  max-width: none !important;
}

.cotizarFillForm {
  display: flex;
  background-color: $color-white;
  border-radius: 7px;
  padding: 0.6rem 0.5rem ;
  input,
  select {
    &:disabled {
      background: $color-gray-light-prod;
      color: #555 !important;
      cursor: not-allowed;
    }
  }
  .footerBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-left: 15px;
    button{
      position: relative;
      svg{
        position: absolute;
        right: 4%;
        top: 35%;
      }
    }
  }
  .footerBtnBanner {
    width: 100%;
    padding-left: 15px;
    margin-top: 15px;
  }
  .formContent {
    margin: 4px 10px;
    width: 65%;
    display: flex;
    flex-direction: column;
  }
  .formContentEnglish {
    margin: 4px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .footerRight {
      justify-content: flex-end;
      padding-right: 15px;
      @media screen and (min-width: 400px) and (max-width: 600px ) {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px !important;
        margin-left: -10px;
        align-self: flex-start;
        .cotizarFillForm {
          padding-top: 0;
        }
         
      }
      @media screen and (max-width: 400px){
        max-width: 100%;
        button {
          max-width: 100%;
        }
      }
    }
  }
  .adjustInBanner {
    width: 100%;
  }
  .faqContent {
    margin: 4px 10px;
    padding: 6px;
    border-left: 1px solid $color-gray;
    width: 30%;
    .title {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .question {
      font-size: 14px;
    }
    .answer {
      font-size: 14px;
    }
  }
  .errorArea {
    .errorLabel {
      font-size: 0.8em;
      color: $color-red-error;
      border: 1px solid $color-red-error-border;
      padding: 2px 5px;
      width: 100%;
    }
  }
}
@media (max-width: $width-extra-large) {
  .cotizarFillForm {
    flex-direction: column;
    width: 100%;
    .formContent {
      width: 95%;
    }
    .faqContent {
      border-left: none;
      width: 95%;
      text-align: justify;
    }
  }
}
