@import '../../styles/variables';

.footer {
  padding: 1% 4% 0;
  background-color: $dark-grey;
  .footerUp {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    padding: 12px 10px 50px;
    z-index: 1000;
    .leftInfo {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      margin: 0px;
      padding-left: 0;
      padding-right: 0;
      .wrapImg {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      .img {
        width: 75px;
        height: auto;
      }
      a {
        clear: both;
        display: block;
        float: left;
        font-size: 12px;
        margin-top: 5px;
        &:visited {
          text-decoration: $color-blue;
          color: $color-blue;
        }
      }
      p {
        clear: both;
        display: block;
        float: left;
        font-size: 18px;
        margin: 0;
        color: $color-gray-light;
      }
    }
    .centerInfo {
      flex: 3 3;
      .inlineOptions {
        padding-top: 45px;
        ul {
          padding: 0;
          display: flex;
          list-style-type: none;
          align-items: center;
          justify-content: flex-end;
        }
      }
      .columnOptions {
        display: flex;
        justify-content: space-around;

        ul {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          text-align: left;

          li {
            p {
              padding: 0;
              font-size: 18px;
              margin-bottom: 2%;
            }
            a {
              text-decoration: none;
              line-height: 1.2;
              font-size: 18px;
              clear: both;
              display: block;
              margin: 0;
              color: $color-gray-light;
            }
          }
        }
      }
      .columnOptionsAr {
        justify-content: space-between;
        max-width: 70%;
        ul {
          padding: 0;
        }
        @media (max-width: $width-medium) {
          max-width: none;
        }
      }
    }
    .rightInfo {
      display: flex;
      flex-direction: column;
      .phoneText {
        color: $color-white;
        .title {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0px;
          text-align: center;
        }
        .number,
        .number a {
          font-weight: bold;
          font-size: 32px;
          margin-bottom: 40px;
          text-align: center;
          color: $color-white;
        }
        .number a:hover {
          text-decoration: none;
        }
      }
      .icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .wrapImg {
          margin: 6px 10px;
          img {
            max-width: 60px;
            height: auto;
            @media (max-width: 780px) {
              max-width: 50px;
            }
          }
        }
      }
      .bannerImg {
        display: flex;
        justify-content: center;
        .wrapImg {
          margin: 6px 10px;
          img {
            max-width: 60px;
            height: auto;
            @media (max-width: 780px) {
              max-width: 80px;
            }
          }
        }
      }
    }
  }
  .customize-cookie-options-button {
    appearance: none;
    outline: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-size: 18px;
    color: #f9fafb;
    font-family: "Source Sans Pro Regular";
    text-align: left;

  }
  .footerDown {
    margin-top: 1%;
    padding: 0;
    text-align: center;
    display: flex;
    .bottomInfo {
      width: 100%;
      align-items: center;
      color: $color-dark-gray-less;
      font-size: 16px;
      line-height: 18px;
      img {
        display: block;
        margin-bottom: 1%;
      }
      .imgsLine {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70px;
          height: 35px;
          margin: 0 10px;
        }
      }
      .imgsLineAR {
        img {
          width: 90px;
        }
      }
      .text {
        width: 70%;
        margin: 3% auto;
      }
    }
  }
  @media (max-width: 600px) {
    .footerUp {
      flex-direction: column;
    }
    .footerDown {
      .bottomInfo {
        .imgsLine {
          img {
            width: 60px;
            height: 28px;
          }
        }
      }
    }
  }
  @media (max-width: $width-medium) {
    .footerUp {
      .centerInfo {
        .inlineOptions {
          padding-top: 20px;
          ul {
            width: 100%;
            display: inline-table;
            li {
              text-align: center !important;
            }
          }
        }
        .columnOptions {
          flex-direction: column;
          ul {
            padding-left: 0;
          }
        }
      }
    }
  }
}
