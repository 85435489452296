@import '../../../../styles/variables';

.title {
  font-size: 24px;
  color: $color-black;
  font-weight: bold;
  text-align: center;
}

.formTitleDiv {
  width: 100%;
  text-align: center;
}
@media (max-width:440px){
  .formTitleDiv {
      padding:0 !important
    }
  
}
