@import '../../../../../../styles/variables';

.ageItem {
  margin-right: 3px;
  input {
    width: 55px;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: 2px solid #d9e2e9;
    border-radius: 5px;
  }
}
