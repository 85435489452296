@import '../../styles/_variables.scss';

.promociones {
  .rounded {
    padding-left: 5%;
  }
  .header {
    padding-left: 5%;
    h1 {
      font-weight: bold;
      color: $color-dark-gray-medium;
    }
  
  }
  .item {
    margin-bottom: 4%;
    .text {
      padding-top: 5%;
    }
  }

  div {
    padding: 0;
  }

  
  h2 {
    font-weight: bold;
    color: $color-dark-gray-medium;
  }

  img {
    width: 90%;
    padding: 0;
  }

  p {
    color: rgb(85, 85, 85);
    font-size: 0.9em;
    text-align: justify;
  }
  @media (max-width: $width-medium) {
    .rounded {
      padding-top: 5%;
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 0.8em;
    }
  }
}
