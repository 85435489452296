@import '../../styles/variables';

.planes {
  width: 100%;
  .wrapBanner {
    width: 100%;
    margin-bottom: 1%;
    img {
      width: 100%;
      max-height: 300px;
    }
  }
  .planesHeader {
    color: #555;
    margin: 1% 2.5% 1%;
    padding: 0 15px;
    h4 {
      font-family: $font-bold;      
      font-weight: bold;      
      line-height: 50px;
      text-rendering: optimizelegibility;
    }
    p {
      font-size: 16px;
      font-weight: 100;
      line-height: 20px;
      margin-bottom: 2%;
      text-align: justify;
      a {
        color: rgb(255, 97, 90);
        text-decoration: none;
      }
    }
  }
  .content {   
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}
.planes > div.wrapBanner > div > img{
  height: 300px;
  
  
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnArrow{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 999;
  cursor: pointer;
}

.btnLeft{
  left: -60px;
}
.btnLeft, .btnRight{  
  height: 35px;
  width: 35px;
  background: $color-white;
  text-align: center;
  border-radius: 5px;
}
.btnLeft svg,.btnRight svg{
  color: $color-dark-gray;
  width: 20px;
  height: 26px;
  padding-top: 10px;
}
.btnRight{
  right: -60px;
}

@media screen and (max-width: 992px) {
  .btnLeft{
    left: -20px;
  }
  .btnRight {
    right: -20px;
  }

  
}
@media (max-width: 768px){
  .planes{
    .planesHeader{
      padding: 0 15px 5px;
    }
  }
}
@media screen and (max-width: 820px) {
  .btnLeft{
    left: -10px !important;
  }
  .btnRight {
    right: -10px !important;
  }

  
}