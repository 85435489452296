@import '../../styles/_variables.scss';

.none {
  display: none !important;
}

@media (max-width: $width-medium) {
  .none {
    display: block!important;
  }
}

.block {
  display: block !important;
}

.dropDown {
  .wrapItem {
    display: flex;
    align-items: center;
    .title {
      position: relative;
      margin-bottom: 0;
    }
    img {
      margin-right: 5px;
      max-width: 25px;
      max-height: 25px;
    }
  }
  .menu {
    position: absolute;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: $color-white;
    padding: 0;
    min-width: 140px;
    z-index: 1000;
    margin-left: 0 !important;
    margin-top: 0 !important;
    li {
      padding: 6px 15px;
      margin-right: 0 !important;
      &:hover {
        background: $color-clean-blue;
        cursor: pointer;
      }
    }
  }
  a {
    color: $color-blue;
    text-decoration: none;
  }
  @media (max-width: $width-medium) {
    .menu {
      background: transparent;
      border: 0;
      box-shadow: 0 0 0 0;
      position: inherit;
      li {
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
