@import '../../styles/variables';

.wrapSteps {
  display: flex;
  // align-items: center;
  min-width: 20%;
  padding-top: 20px;
  @media screen and (min-width: 992px) {
    min-width: 40px;
    padding-top: 50px;
  }
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-light;
    width: 20px;
    height: 20px;
    border-radius: 70%;
    position: relative;
    .stepDot {
      background-color: $color-gray-light; 
      border-radius: 70%;
      width: 10px;
      height: 10px;
    }
    @media screen and (min-width: 1045px){
      background-color: $color-white;
      .stepDot {
        background-color: $color-white;
      }
    }
    .label {
      display: none;
      @media screen and (min-width: 1045px){
        display: block;
        position: absolute;
        font-size: 12px;
        bottom: 100%;
        color: $color-gray-light;
        white-space: nowrap;
      }
 
    }
    .currentLabel {
      color: $color-white !important;
      font-weight: bold;
    }
  }
  .bar {
    background-color: $color-gray-light;
    flex: 1 1;
    margin-top: 6px;
    max-height: 5px;
    min-width: 60px;
    @media screen and (min-width: 420px) {
      min-width: 100px;
    }
    @media screen and (min-width: 992px) {
      min-width: 80px;
    }

    @media screen and (min-width: 1045px){
      background-color: $color-white;
    }
  }

  .stepSelected {
    background-color: $color-orange !important;
  }

  .stepDone {
    background-color: $color-dark-gray !important;
  }
}


