@import '../../../styles/_variables.scss';

.planesItem {
  margin-bottom: 3%;
  .planesItemImage {
    text-align: center;
    img {
      height: auto;
      width: 100%;
      &.cicle {
        border-radius: 14%;       
        padding: 2% 9%;
      }

    }
  }
  .planesItemDetail {    
    padding: 0 9%;
    .planesItemDetailTitle {
      h6 {       
        font-family: $font-bold !important;
        font-size: 20px;
        font-weight: bold;
        line-height: 29px;
        margin: 4% 0 !important;
        padding: 0.5rem 0;
      }
    }
    .planesItemDetailText {
      font-size: 13px;
      font-family: $font-regular;
      font-weight: normal;
      color: #555 !important;
      margin-bottom: 20px;
      line-height: 1.6;
      text-align: left;
      height:165px;
      
    }
    .plansItemBtn{
      a, a:active {
        background-color: $kayak-blue;
        border-color: $kayak-blue;
        font-weight: 400;
        text-decoration: none;
      }
      
    }
  }
 @media (max-width: 500px) {
    
      .planesItemBtn{
        margin-bottom: 0 !important;
      }
    
    
  } 
  @media (max-width: $width-large) {
    .planesItemImage {
      img {
        width: 100%;
      }
    }
    .planesItemDetailText {
      
      height:auto !important;
      
    }
    .planesItemDetailTitle {
      h6 {       
        height:auto !important;
      }
    }
  }
  @media (max-width: $width-medium) {
    .planesItemImage {
      img {
        width: 100%;
      }
    }
    .planesItemDetailText {
      
      height:auto !important;
      
    }
    .planesItemDetailTitle {
      h6 {       
        height:auto !important;
      }
    }
  }
}
