@import '../../styles/_variables.scss';

.none {
  display: none;
}
.customNav {
  min-height: 70px;
  justify-content: space-between;
  padding: 0.5% 4%;
  .btnMobileNav {
    span {
      color: $color-blue;
    }
  }
  .navBarBrandLogoAXA {
    height: 72px;
    margin-left: 60px;
  }
  .navBarBrandLogoKayak {
    height: 65px;
    margin-right: 60px;
  }
  .wrapOptions {
    display: flex;
    align-items: center;
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      li {
        color: $color-blue;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        p {
          color: $color-blue;
        }
      }
      .btnCotizarArea {
        button {
          min-width: 150px;
          font-size: 12px;
        }
      }
      li:last-child {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .stepsHeader {
    margin-right: 10px;
    flex: 1 1;
    display: contents;
  }

  .buttonCotizar {
    margin-left: 10px;
    button {
      text-transform: none;
      padding: 10px 20px;
    }
  }

  @media (max-width: $width-medium) {
    flex-wrap: wrap;
    .btnMobileNav {
      display: none !important;
    }
    .navBarBrandLogoAXA {
      height: 72px;
      margin-left: 0px !important;
    }
    .navBarBrandLogoKayak {
      margin-right: 0px !important;
    }
    .wrapOptions {
      width: 100%;
      &.close {
        display: block !important;
      }
      &.open {
        display: flex;
        flex-basis: 100%;
      }
      ul {
        padding: 2% 0;
        width: 100%;
        flex-direction: column;
        li {
          width: 95%;
          padding-left: 20px;
          margin-bottom: 2%;
          &.noMarginBottom {
            margin-bottom: 0;
          }
          ul {
            margin-bottom: 0;
            padding-bottom: 0;
            width: 80%;
            li {
              text-align: left;
              &:last-child() {
                margin-bottom: 0;
              }
            }
          }
          a {
            color: black;
          }
          p {
            color: $color-white;
            font-size: 16px;
            font-weight: bold;
          }
          p {
            padding-left: 0;
          }
        }
      }
    }
  }
  @media (max-width: $width-large) {
    .wrapOptions {
      ul {
        li {
          margin-right: 18px;
        }
      }
    }
  }
  @media (max-width: $width-medium) {
    .wrapOptions {
      ul {
        li {
          a {
            button {
              font-size: 20px !important;
              font-weight: bold !important;
              margin-left: 0 !important;
              padding-left: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .logoAxaHeader {
    display: none;
  }
}
.mobileContactInfo {
  width: 100%;
}

.contactanosHeaderOption {
  @media screen and (max-width: 480px) {
    li {
      margin: 0 !important;
      padding: 0 !important;
    }

    ul {
      padding: 0 !important;
      margin: 0 !important;
    }

    a {
      font-size: 0.8rem;
    }

    img {
      width: 16px;
    }
  }
}
