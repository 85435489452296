@import '../../../../styles/variables';


.header {
  text-align: center;
  .icon {
    text-align: center;
  }
  
  .title {
    font-size: 30px;
    font-family: $font-bold;
    color: $dark-grey;
    font-weight: bold;
    text-align: center;
    max-width: 610px;
    margin: auto;
  }
  
  .subtitle {
    text-align: center;
    font-family: $font-semibold;
    margin: 30px auto;
    font-size: 24px;
    color: #323232;
    margin-bottom: 50px;
    strong{
      font-family: $font-bold;
    }
  }
}


.btnsList {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding: 0;
  li {
    margin-right: 40px;
    list-style: none;
    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}

.feedbackArea {
  margin: 30px 0;
}

.youtube {
  img {
    width: 100%;
  }
}

@media (max-width: $width-extra-large) {
  .btnsList {
    flex-direction: column;
    li {
      width: 100%;
      justify-content: space-between;
      list-style: none;
      margin: 25px 0 25px 0;
    }
  }
}

.btnLeaveOpinion {
  width: 100%;
  max-width: 400px;
  color: white;
  background-color: $color-blue-dark;
  border: 2px solid $color-blue-dark;
  border-radius: 7px;
  font-size: 24px !important;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  margin-bottom: 90px;
  margin-top: 40px;
}
.aLeaveOpinion {
  width: 100%;
  max-width: 400px;
  color: white;
  background-color: $color-blue-dark;
  border: 2px solid $color-blue-dark;
  border-radius: 7px;
  font-size: 24px !important;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 20px;
  text-decoration: none !important;
}
.aLeaveOpinion:hover{
  color: white;
}

.linksList{
  ul{
    padding: 0;
    list-style-type: none;
    li{
      button{
        margin: 20px 0 30px;
      }
    }
  }
}

.passengerList{
  .passengerItem{
    display:flex;
    justify-content: center;
    font-size: 18px;
    .name{
      color:#323232;
      margin-right: 6px;
    }
    .lastName{
      margin-right: auto;
      color:#323232;
    }
    .icon{
      height: auto;
      width: 35px;
    }
  }
}

.separatingLine{
  border-top: 1px dashed #cdcdcd;
  margin: 3px;
}
.greenCheck{
  text-align: center;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  margin-bottom: 1rem;
}