@import '../../styles/variables';

.Calendar {
  display: flex;
  flex-direction: column;
  position: relative;
  input {
    border: none;
    border: 1px solid $border-color-input;
    font-size: 16px;
    line-height: 1;
    padding: 0 12px;
    width: 100%;
    border-radius: 5px;
    letter-spacing: 1.5px;
    padding-left: 30px;
    // height: calc(1.5em + 0.75rem + 2px);
    height: 35px;

    &:focus {
      outline: none;

      box-shadow: none;
      border-color: $color-gray;
    }
    &::placeholder {
      line-height: 16px;
    }
    &:-webkit-autofill {
      background: red;
    }
    &:disabled {
      background-color: #fafafa;
      cursor: not-allowed;
    }
  }
  .icon {
    max-width: 18px;
    max-height: 18px;
    opacity: 0.3;
    position: absolute;
    left: 8px;
    top: 10px;
  }
}
.error {
  input {
    border-color: $color-red-error !important;
  }
}

.errorLabel {
  margin: 4px 0 0 0;
  font-size: 0.72em;
  color: $color-red-error;
  border: 1px solid $color-red-error-border;
  padding: 2px 5px;
}

.label {
  position: absolute;
  top: -14px;
  font-size: 11px;
  color: #bababa;
}
