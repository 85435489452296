@import '../../styles/variables';

.tooltip {
  position: relative;
  img {
    border-radius: 300px;
    margin: 0 !important;
    vertical-align: baseline;
    max-width: 22px;
    max-height: 22px;
    opacity: 0.5;
  }
  .content {
    position: absolute;
    padding: 4px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    background-color: $color-white;
    min-width: 150px;
    font-size: 11px;
    color: $color-dark-gray-less;
    text-align: justify;
    z-index: 100;
  }
}
