@import '../../../styles/_variables.scss';

.planesDetail {
  width: 100%;
  .wrapBanner {
    width: 100%;
    margin-bottom: 3%;
    img {
      width: 100%;
      max-height: 570px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2%;
    width: 100%;
    .description {
      width: 90%;
      padding: 0 5%;
      margin: 0 auto 2% auto;
      
      @media  screen and (max-width: 768px) {
        padding: 0 2%;
        width: 100%;
      }
      .title {
        @media screen and (max-width: 768px){
          margin-bottom: 20px;
        }
        h1 {
          color: #555;
          font-family: $font-bold;
          font-size: 45px;
          font-weight: bold;
          line-height: 35px;
          margin: 10px 0;
          text-align: center;
          
        @media  screen and (max-width: 820px) {
          text-align: left !important;
        }
        }
        h2 {
          color: #555;
          font-family: $font-bold;
          font-size: 40px;
          font-weight: bold;
          margin: 0;
          text-align: center;
        }
        &.fullTitle {
          width: 100% !important;
          h2 {
            margin-bottom: 2%;
          }
        }
      }
      .text {
        border-left: solid 1px rgb(230, 230, 230);
        @media screen and (max-width: 768px){
          border-left: none;
        }
        text-align: justify;
        color: #555;
        font-family: Helvetica Neue, Helvetica !important;
        font-size: 14px;
        font-weight: 200;
        line-height: 20px;
        text-shadow: none !important;
        p {
          padding-left: 10%;
        }
      }
    }
    .table {
      width: 70%;
      margin: 0 auto 1% auto;
      .title {
        display: flex;
        font-family: $font-bold;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
        .colLeft {
          width: 56%;
          background: rgb(0, 0, 144);
          color: white;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          font-size: 23px;
          
          height: 46px;
          text-align: center;
          line-height: 47px;
          margin: 0;
        }
        .colLeftMx {
          width: 56%;
          background: rgb(0, 0, 144);
          color: white;
          // border-top-left-radius: 50px;
          // border-bottom-left-radius: 50px;
          font-size: 15px;
          
          height: 46px;
          text-align: center;
          line-height: 47px;
          margin: 0;
        }
        .colRight {
          width: 34%;
          background: rgb(6, 6, 102);
          color: white;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          font-size: 23px;          
          text-align: center;
          line-height: 47px;
          height: 46px;
        }
        .colRightMx {
          width: 34%;
          background: rgb(6, 6, 102);
          color: white;
          // border-top-right-radius: 50px;
          // border-bottom-right-radius: 50px;
          font-size: 15px;          
          text-align: center;
          line-height: 47px;
          height: 46px;
        }
        @media screen and (max-width: 500px) {
          .colRight, .colLeft {
            font-size: 18px;
          }
        }
      }
      .tableContent {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .planesDetailsContainer{
          width: 100%;
          flex-direction: row;
          display: flex;
        }
        .planesDetailsContainerMx{
          width: 100%;
          flex-direction: row;
          display: flex;
        }
        .planesDetailsContainerMx:nth-child(even){
          background-color: aliceblue;
        }
        .plansDetaisItemLeft {
          width: 56%;
          color: #00008f;
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 15px;
            text-align: left;
            padding: 15px 0;
            img {
              float: left;
              height: 100%;
              text-align: right;
              max-height: 25px;
            }
          }
        .plansDetaisItemLeftMx {
            width: 63%;
            color: #00008f;
              display: block;
              font-size: 14px;
              font-weight: bold;
              line-height: 15px;
              text-align: left;
              padding: 15px 0;
              img {
                float: left;
                height: 100%;
                text-align: right;
                max-height: 25px;
              }
            }
        .plansDetaisItemRight {
          border-left: solid 1px #e6e6e6;
          width: 34%;
          font-size: 14px;
          width: 38%;
            color: #555;
            display: block;
            font-weight: bold;
            text-align: center;  
            padding: 15px 0;
        }

        @media screen and (max-width: 768px){
          .plansDetaisItemLeft {
            width: 50%;
            img {
              display: none;
            }
          }
          .plansDetaisItemRight {
            width: 50%;
          }
        }
      }
    }
    .compare {
      width: 75%;
      margin: auto;
      text-align: right;
      padding-bottom: 1rem;
      a {
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-size: 13px;
        text-decoration: none;
        @media screen and (max-width: 768px){
          margin-right: 6rem;
        }
      }
    }
  }
  @media (max-width: $width-medium) {
    .content {
      .description {
        .title {
          h1 {
            font-size: 40px;
          }
          h2 {
            font-size: 40px;
          }
        }
      }
      .table {
        width: 100%;
        .title {
          .colLeft {
            width: 50%;
          }
          .colRight {
            width: 50%;
          }
        }
        .tableContent {
          .colLeft {
            width: 50%;
            span {
              line-height: 25px;
              img {
                display: none;
              }
            }
          }
          .colRight {
            width: 50%;
          }
        }
      }
      .compare {
        width: 100%;
      }
    }
  }
}
