@import '../../styles/variables';
.promotions_banner_container{
    display: relative;
    position: relative;
    width: 100%;
    max-width: 100%;
    // height: 75px;
    overflow: hidden;
}

img{
  vertical-align: inherit;
}
.imgBanner{
  width: 100%;
}

.buttonClose{
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 40px;
  opacity: 0.2 !important;
}

.buttonClose:hover{
  opacity: 0.9 !important;
  transition-duration: 0.2s;
}
