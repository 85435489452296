@import '../../styles/variables';
.CheckBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  .checkArea {
    display: flex;
    justify-content: space-between;
    img {
      height: 18px;
      margin-right: 15px;
    }
  }
}

.errorLabel {
  font-size: 0.75em;
  color: $color-error-red-alert;
}
