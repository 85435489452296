@import '../../../../styles/variables';

.formBarStep1{
  width: 80%;
  display: flex;
  @media(max-width: 1200px){
    width: 100%;
    // flex-direction: column;
    display: block;
  }
  .formContent>div{   
      padding-left: 0.5rem !important;   
  }
}