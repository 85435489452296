@import '../../../../../styles/variables';

.rentalPeriodlArea {
  margin-top: 0.7rem;
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
  }
  input {
    background-color: $color-white !important;
    border: 2px solid $color-gray;
    border-radius: 5px;
  }
  select {
    max-width: 220px;
    background-color: $color-white !important;
    border: 2px solid $color-gray !important;
    border-radius: 5px;
    @media screen and (max-width: 1045px) {
      max-width: 100% !important;
    }
  }
}

.labelTitle {
  font-size: 16px;
  color: $color-gray-light5;
  margin-bottom: 0px;
}
