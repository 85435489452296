@import '../../styles/_variables.scss';

.responsivetitle{
  margin-top: auto;
}
.responsivetext{
  
  @media screen and (max-width: 525px) {
      font-size: 10px !important;
      line-height: 1.4;
  }
}
.responsivetitle{
  @media  (min-width:300px) and (max-width: 525px) {
    font-size: 0.6rem !important;
    font-weight: bold;
    
  }
  @media screen and (min-width: 525px) and (max-width: 645px) {
    font-size: 1.5rem !important;
    font-weight: bold;

  }
}
