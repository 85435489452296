@import "../../../../../styles/variables";

.step3Body{
  display: flex;

  .leftBody{
    width: 60%;
    height: fit-content;
  }

  .rightBody{
    width: 40%;
    height: fit-content;
    margin-left: 4%;
  }
}

@media (max-width: $width-extra-large) {
  .step3Body {
    flex-direction: column;
    align-items: center;
    .leftBody{
      width: 80%;
    }
  
    .rightBody{
      width: 80%;
      margin-left: 0;
    }
  }
}

@media (max-width: $width-small) {
  .step3Body {
    .leftBody{
      width: 100%;
    }
  
    .rightBody{
      width: 100%;
    }
  }
}