@import '../../../styles/_variables.scss';
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 3%;
  width: 100%;
  .row {
    margin: 0;
    width: 100%;
    .left {
      font-weight: bold;
      text-align: center;
      font-family: $font-bold;
      .red {
        color: rgb(255, 23, 33);
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        &.fontMedium {
          font-size: 25px;
          line-height: 30px;
        }
      }
      .blue {
        color: rgb(0, 0, 143);
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        &.fontMedium {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
    .center{
      align-self: center;
    }
    .right {
      color: rgb(0, 0, 143);
      @media screen and (min-width: 769px) {
        border-left: 1px solid #e0e0e0;
      }
      font-size: 13px;
      font-weight: 200;
      line-height: 20px;
      padding: 0 5%;
      ul {
        padding: 0;
      }
      p {
        text-align: justify;
      }
    }
  }
  .space {
    background-color: rgb(184, 184, 184);
    height: 15px;
    margin: 2% 0;
    width: 100%;
  }
}
