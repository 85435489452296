@import '../../styles/_variables.scss';

.navOption {
  color: $color-blue;
  font-size: 11px;
  font-weight: bold;
  margin-right: 10px;
  padding-left: 0;
  a {
    display: flex;
    align-items: center;
    color: #323232;
    text-decoration: none;
    font-size: 15px;
  }
  p {
    margin: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: default;
    }
  }
  .imgIcon {
    filter: opacity(0.5) drop-shadow(0 0 0 black) drop-shadow(0 0 0 black);
    margin-right: 5px;
    max-width: 25px;
    max-height: 25px;
  }


  @media (max-width: $width-large) {
    margin-right: 0;
  }
  @media (max-width: $width-medium) {
    margin-right: 0;
    text-align: center;
    a{
      display: inline-block;
    }
    p{
      display: inline-block;
    }
  }

}
