//COLORS
$kayak-blue: #0080a4;
$dark-grey:#232a2f;
$plan-colo:#e8381b;
$color-success-green : #14884c;


$color-white: #fff;
$color-light-gray: #f9fafb;
$color-clean-gray: #f0f3f5;
$color-gray: #d9e2e9;
$color-dark-gray: #363f45;
$color-dark-gray-medium: #5a6872;
$color-dark-gray-less: #9ba8b0;
$color-blue: #00485c;
$color-blue-dark: #07647e;
$color-light-blue: #007799;
$color-clean-blue: #e0f8ff;
$color-red-error: #d32933;
$color-red-button: #e8381b;
$color-red-banner: #d32933;
$color-red-error-border: #d32933;
$color-orange: #cf3218;
$color-gray-light-prod: #ffffff;
$border-color-input: #d9e2e8;
$color-blue-pls-minus-btns:#07647e;

//FONTS
$font-regular: 'Source Sans Pro Regular';
$font-light: 'Source Sans Pro Light';
$font-bold: 'Source Sans Pro Bold';
$font-semibold: 'Source Sans Pro SemiBold';
$font-black: 'Source Sans Pro Black';



/* FONTS */
$font-publicBanner: 'Publico Banner';
$font-publicoBlack: 'Source Sans Pro Black';
$font-sansProLight: 'Source Sans Pro Light';
$font-sansProRegular: 'Sans Pro Regular';
$font-latoRegular: 'Source Sans Pro Regular';
$font-latoBlack: 'Source Sans Pro Black';
$font-latoLight: 'Source Sans Pro Light';
$font-latoBold: 'Source Sans Pro Bold';

//COLORS
// $color-blue: #1a1f71;
$color-blue-light: #e0f8ff;
$color-blue-hover: #07647e;
$color-blue-dark: #007799;
$color-brown: #6d2f03;
$color-brown-light: #fffce0;
$color-gold: #fff2e0;
$color-gray-dark: #363f45;
$color-gray-light: #f9fafb;
$color-gray-light2: #f0f3f5;
$color-gray-light3: #9ba8b0;
$color-gray-light4: #5a6872;
$color-gray-light5: #363f45;
$color-gray-background: #d9e2e9;
$color-black: #192024;
$color-error-red: #d32933;
$color-error-red-hover: #e8381b;
$color-error-red-alert: #d32933;
$color-red-button: #d32933;

$color-success-green2: #ccffe5;
$color-success-green-hover: #074a28;
$color-yellow-action: #fff2e0;
$color-orange-hover: #e55e0d;
$color-orange-light: #eb381b;
$color-organge-dark: #cf3218;
$color-green-dark: #074a28;
$color-green-confirmation: #14884c;
$color-green-light: #ccffe5;
$color-green-medium: #14884c;
$color-hours-button: #e8381b;

//FONT SIZE
$font-size-itemTitle: 22px;
$font-size-itemTitleMobile: 20px;
$font-size-itemContent: 16px;
$font-size-itemContentMobile: 12px;

//WIDTH - GRID
$width-small: 576px;
$width-medium: 768px;
$width-large: 992px;
$width-extra-large: 1200px;
