@import 'styles/variables.scss';

.boxApp {
  // max-width: 1280px;
  margin: auto;
  overflow-x: hidden;
  .body {
    // padding: 5px 0px 10px;
    width: 100%;
    min-height: calc(100vh - 402px);
    @media (max-width: 768px) {
      padding: auto;
    }
  }
}
