@import '../../../../../../styles/_variables.scss';

.travelerIndex{
  margin-left: -12px;
  .labelTitle{
    font-family: $font-regular;
    font-size: 14px;
  }
}

.PersonArea {
  padding: 0 2% 2%;
  margin-bottom: 4%;
  background: #fff;
  border-radius: 5px;

  .labelTitle {
    font-family: $font-regular;
    font-size: 14px;
    margin: 0.5em 0px 0px 0px;
  }

  .inputField {
    div {
      margin-top: 0px;
    }
  }

  select{
    background-position: 99% 54%;
  }
}

@media(max-width: $width-small){
  .travelerIndex{
    margin-left: 0;
  }
}
