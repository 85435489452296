@import '../../styles/variables';

.Select {
  position: relative;
  font-family: Myriad Pro Light;
  select {
    color: #555;
    border: 1px solid $border-color-input;
    height: 35px;
    // max-width: 220px;
    min-width: 100px;
    width: 100%;
    border-radius: 5px;
    background: #fff;
    background-image: url('../../assets/img/icons8-expand-arrow.png');
    background-repeat: no-repeat;
    background-position: 96% 54%;
    background-size: 15px;
    appearance: none;
    font-family: Sans Pro Regular;
    padding: 0px 12px;
    &:focus {
      outline: $color-gray-light2;
      box-shadow: $color-gray-light2;
      border-color: $color-gray-light2;
    }
  }
  select::-ms-expand {
    display: none;
  }
  .error {
    border-color: $color-red-error !important;
  }
}

.errorLabel {
  margin: 4px 0 0 0;
  font-size: 0.72em;
  color: $color-red-error;
  border: 1px solid $color-red-error-border;
  padding: 2px 5px;
}

.label {
  position: absolute;
  top: -14px;
  font-size: 11px;
  color: $color-gray-light2;
}

.nice-select {
  color: $color-gold;
  width: 100%;
  border: 1px solid $color-gold;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  height: 36px;
  line-height: 38px;
  float: none;
  &:focus,
  &:hover,
  &.open {
    border-color: $color-brown;
  }
  &.open {
    &:after {
      transform: rotate(180deg);
    }
  }
  &:after {
    background: url('../../assets/img/triangle.png');
    border: none;
    width: 13px;
    height: 7px;
    -webkit-background-size: 100%;
    background-size: 100%;
    transform: rotate(0deg);
    -webkit-transform-origin: initial;
    -ms-transform-origin: initial;
    transform-origin: initial;
  }
  .list {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: $color-gray-dark;
    padding: 5px 0;
    overflow: visible;
    margin-top: 10px;
    width: 100%;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 7px solid $color-white;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      top: -6px;
    }
    .option {
      min-height: 26px;
      line-height: 26px;
      &:hover,
      &.focus,
      &.selected.focus {
        background: $color-gray;
      }
    }
  }
}

.defaultStyle {
  background-color: $color-white;
  padding-left: 12px;
  appearance: none;
  background-image: url('../../assets/img/icon-arrow-down-yellow.svg');
  background-position: 93% 50%;
  background-repeat: no-repeat;
  background-size: 22px;
}

.selectHideArrow {
  select::-ms-expand {
    display: none;
  }
}
